import { Avatar, Card, CardHeader, Grid, IconButton, Link } from '@mui/material';
import MuiTypography from '@mui/material/Typography';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@mui/material/styles';

// ==============================|| TYPOGRAPHY ||============================== //

const LiveChat = () => (
    <MainCard title="Live Chat" secondary={<SecondaryAction link="https://radio.insharp.life" />}>
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={3}>
                <SubCard title="Chats">
                    <Grid container direction="column" spacing={1}>
                        <ChatTitle Name="Aravinda" Company="Insharp" key={1} />
                        <ChatTitle Name="Kavindu" Company="Insharp" key={2} />
                        <ChatTitle Name="Vishwa" Company="Bizmo" key={3} />
                    </Grid>
                </SubCard>
            </Grid>
            <Grid item xs={12} sm={6}>
                <SubCard title="Sub title">
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <MuiTypography variant="subtitle1" gutterBottom>
                                subtitle1. Lorem ipsum dolor sit connecter adieu siccing eliot. Quos blanditiis tenetur
                            </MuiTypography>
                        </Grid>
                        <Grid item>
                            <MuiTypography variant="subtitle2" gutterBottom>
                                subtitle2. Lorem ipsum dolor sit connecter adieu siccing eliot. Quos blanditiis tenetur
                            </MuiTypography>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
            <Grid item xs={12} sm={4}>
                &nbsp;
            </Grid>
            <Grid item xs={12} sm={6}>
                <SubCard title="Extra">
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <MuiTypography variant="button" display="block" gutterBottom>
                                button text
                            </MuiTypography>
                        </Grid>
                        <Grid item>
                            <MuiTypography variant="caption" display="block" gutterBottom>
                                caption text
                            </MuiTypography>
                        </Grid>
                        <Grid item>
                            <MuiTypography variant="overline" display="block" gutterBottom>
                                overline text
                            </MuiTypography>
                        </Grid>
                        <Grid item>
                            <MuiTypography
                                variant="body2"
                                color="primary"
                                component={Link}
                                href="https://radio.insharp.life"
                                target="_blank"
                                display="block"
                                underline="hover"
                                gutterBottom
                            >
                                https://radio.insharp.life
                            </MuiTypography>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    </MainCard>
);

export default LiveChat;

function MoreVertIcon() {
    return null;
}

const ChatTitle = ({ sx = {}, Name, Company, key }) => {
    const theme = useTheme();
    return (
        <Grid item key={key}>
            <Card
                sx={{
                    border: '1px solid',
                    borderColor: theme.palette.primary.light,
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    },
                    ...sx
                }}
            >
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: 'grey', color: 'white' }} aria-label="recipe">
                            {Name.charAt(0)}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={Name}
                    subheader={Company}
                />
            </Card>
        </Grid>
    );
};

import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Button from '@material-ui/core/Button';
import { Form, Grid, TextArea } from 'semantic-ui-react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapForm: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            margin: `${theme.spacing(0)} auto`,
            flexDirection: 'column'
        },
        wrapText: {
            width: '100%'
        },
        buttonDesktop: {
            // margin: theme.spacing(1),
            borderRadius: '50px',
            width: '10px'
        },
        buttonMobile: {
            borderRadius: '50px',
            width: '10px',
            marginTop: '10px'
        },
        firstButton: {
            backgroundColor: '#dd4b39'
        },
        secondButtonDesktop: {
            backgroundColor: '#55acee',
            marginLeft: theme.spacing(2)
        },
        iconBlack: {
            color: 'black'
        },
        textAreaStyle: {
            width: '100%'
        },
        secondButtonmobile: {
            backgroundColor: '#55acee'
        }
    })
);

export const TextInput = (props) => {
    const classes = useStyles();

    const isAdminPanel = props.location;
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const renderMobileButtons = (
        <>
            <Grid>
                {isAdminPanel === true ? (
                    <>
                        <Grid.Column width={1}>
                            <Button
                                color="primary"
                                className={`${classes.buttonMobile} ${classes.secondButtonmobile}`}
                                onClick={props.onSendClick}
                                disabled={props.sendDisabled}
                            >
                                <SendIcon className={classes.iconBlack} />
                            </Button>
                        </Grid.Column>
                    </>
                ) : (
                    <>
                        <Grid.Column width={6}>
                            {props.onSongClick ? (
                                <Button className={`${classes.buttonMobile} ${classes.firstButton}`} onClick={props.onSongClick}>
                                    <MusicNoteIcon className={classes.iconBlack} />
                                </Button>
                            ) : null}
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Button
                                color="primary"
                                className={`${classes.buttonMobile} ${classes.secondButtonmobile}`}
                                onClick={props.onSendClick}
                                disabled={props.sendDisabled}
                            >
                                <SendIcon className={classes.iconBlack} />
                            </Button>
                        </Grid.Column>
                    </>
                )}
            </Grid>
        </>
    );

    const renderDesktopButtons = (
        <>
            <Grid.Column width={2}>
                {props.onSongClick ? (
                    <Button className={`${classes.buttonDesktop} ${classes.firstButton}`} onClick={props.onSongClick}>
                        <MusicNoteIcon className={classes.iconBlack} />
                    </Button>
                ) : null}
            </Grid.Column>
            <Grid.Column width={2}>
                <Button
                    color="primary"
                    className={`${classes.buttonDesktop} ${classes.secondButtonDesktop}`}
                    onClick={props.onSendClick}
                    disabled={props.sendDisabled}
                >
                    <SendIcon className={classes.iconBlack} />
                </Button>
            </Grid.Column>
        </>
    );

    return (
        <>
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={12}>
                        <Form>
                            <TextArea
                                className={classes.textAreaStyle}
                                id="standard-text"
                                placeholder="Text Message"
                                value={props.value}
                                onChange={props.onMsgChange}
                            />
                        </Form>
                    </Grid.Column>
                    {isMobile ? renderMobileButtons : renderDesktopButtons}
                </Grid.Row>
            </Grid>
        </>
    );
};
